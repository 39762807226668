import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./devices.module.scss";



const CTA = () => {

    
    function scrollToElement(id){
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth'
        });
    }

    return(
        <div className={styles.buttons}>
            <a onClick={() => scrollToElement('templates')} className={styles.button}>
                Browse Templates
            </a>
            <a onClick={() => scrollToElement('demo')} className={styles.button2}>
                Live Demo
            </a>
        </div>
    )
}


const Hero = () => {

  return (
    <div className={styles.container}>
        <h1>
            <strong>App Promo Videos</strong> Made Simple
        </h1>
        <h3>
        Create eye-catching promo videos in minutes. All in your browser.
        </h3>
        <CTA />
        <div className={styles.imgContainer}>
            <StaticImage
                src='../../../../assets/images/illustrations/app-promo-video-hero-dark.png'
                alt="App Promo Video Maker Demo"
                placeholder="blurred"
            />
        </div>
    </div>
  );
};

export default Hero;
