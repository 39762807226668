import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Header from "../components/Pages/AppPromoVideo/Header/Header";
import Templates from "../components/Pages/AppPromoVideo/Templates/Templates";

import StartupStages from "../components/Pages/Hero/StartupStages/StartupStages";
import VideoElements from "../components/Pages/Hero/VideoElements/VideoElements";
import MockupDemo from "../components/Pages/Hero/MockupDemo/MockupDemo";
import Testimonials from "../components/Pages/AppPromoVideo/Testimonials/Testimonials"
import Pricing from "../components/Common/Pricing/Pricing"

export default () => {
  return (
    <Layout>
      <SEO
        title={"App Promo Video Maker | Customizable 3D Templates"}
        description={
          "Choose from 100s of pre-made templates to create a beautiful promo video for your mobile or web app. It's free."
        }
        ogDescription={
          "Create eye-catching promo videos for your app, in 3D."
        }
        twitterDescription={
          "Create eye-catching promo videos for your app, in 3D."
        }
      />
      <Header />
      <Templates />
      <MockupDemo />
      <VideoElements />
      <StartupStages />
      <Testimonials />
      <Pricing medium />
    </Layout>
  );
};
